import React from 'react';
import Scroll from '../Scroll';

export default function Soporte() {
  return (
    <section className="expertise-section" id="soporte">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-lg-6 content-section my-auto order-0 pl-5">
            <h3 className="font-size__l mb-5 text-primary">
              Soporte y mantenimiento
            </h3>
            <h4 className='h4 text-uppercase'><u>Soporte técnico</u></h4>
            <div className="row mb-4">
              <div className="col-10">
                <p className="font-size__m">
                  Si tienes un proyecto en marcha y solo necesitas un <strong>equipo 
                  experto</strong> que te ofrezca el soporte y el mantenimiento, tenemos la solución. 
                </p>
                <p className='font-size__m'>
                  Actualizaciones, mejora de rendimiento, eliminación de errores... Llámanos siempre que lo necesites.&nbsp;
                  <strong>Sin contratos de permanencia ni servicios mínimos</strong>.
                </p>
              </div>
            </div>
            <h4 className='h4 text-uppercase'><u>Rescate de proyectos</u></h4>
            <div className="row mb-4">
              <div className="col-10">
                <p className="font-size__m">
                  ¿Sientes que el desarrollo de tu eCommerce no avanza? <strong>¿Tu agencia actual no 
                  soluciona tus problemas?</strong> Contacta con nosotros y encontraremos la manera de darle salida y continuación.
                </p>
              </div>
            </div>
            <h4 className='h4 text-uppercase'><u>Hosting</u></h4>
            <div className="row">
              <div className="col-10">
                <p className="font-size__m">
                  Muchos de los problemas de rendimiento de los eCommerce son causados por 
                  la utilización de un hosting insuficiente para la solución escogida.
                </p>
                <p className="font-size__m">Nosotros te indicaremos <strong>el más adecuado en cada caso</strong> para que eso no ocurra.</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 soporte-bg order-1"></div>
        </div>
      </div>
    </section>
  );
}
