import React from 'react';
import Scroll from '../Scroll';

export default function Estrategia() {
  return (
    <section className="expertise-section" id="estrategia">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-lg-6 content-section my-auto order-0 pl-5">
            <h3 className="font-size__l mb-5 text-primary">
              Diseño de estrategia
            </h3>
            <h4 className='h4 text-uppercase'><u>Auditoría</u></h4>
            <div className="row mb-4">
              <div className="col-9">
                <p className="font-size__m">
                  Analizamos tus objetivos, el estado de tu eCommerce y 
                  su deuda técnica para trazar la <strong>estrategia a seguir</strong> y valorar el coste de su implementación.
                </p>
              </div>
            </div>
            <h4 className='h4 text-uppercase'><u>Consultoría ecommerce</u></h4>
            <div className="row">
              <div className="col-9">
                <p className="font-size__m">
                  Te ofrecemos asesoramiento profesional para que tomes las decisiones más acertadas 
                  en cuanto a la <strong>elección de plataforma</strong>, migraciones, integración de sistemas o automatización de procesos.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 estrategia-bg order-1"></div>
        </div>
      </div>
    </section>
  );
}
