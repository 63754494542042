import React from 'react';
import { navigate } from 'gatsby';

export default function Partners() {
  return (
    <section className="content-section pb-0 bg-grey" id="partners">
        <div className="container">
            <div className="row">
                <div className="col-12 text-center mb-5">
                    <h4 className="font-size__l text-secondary">Apostamos por las mejores tecnologías</h4>
                </div>
                <div className="col-12 col-md-4 offset-md-1 mx-auto pr-3 pl-3">
                    <div className="row mb-3 justify-content-center">
                        <img src="https://res.cloudinary.com/drjo0cbtf/image/upload/c_scale,f_auto,q_auto/v1650229229/bekome/proyectos-magento-2_mqbe0i.png"
                        alt="Proyectos Magento 2"
                        className="img-fluid" 
                        width={200}/>
                    </div>
                    <div className='row mb-5 justify-content-center text-center'>
                        <p className='font-weight__700'>Magento 2</p>
                        <p>La solución profesional que ofrece mayor personalización y escalabilidad</p>
                        <button class="learn-more" onClick={() => {navigate("/proyectos-magento-ecommerce/")}}>
                            <span class="circle secondary" aria-hidden="true">
                                <span class="icon arrow"></span>
                            </span>
                            <span class="button-text secondary">Más Información</span>
                        </button>
                    </div>
                </div>
                <div className="col-12 col-md-4 offset-md-1 mx-auto pr-4 pl-4">
                    <div className="row mt-5 mb-5 justify-content-center">
                        <img src="https://res.cloudinary.com/drjo0cbtf/image/upload/c_scale,f_auto,q_auto,w_500/v1652610789/bekome/Partner-Certified-Wordmark-1col-Black_asxbwg.png"
                        alt="Proyectos BigCommerce"
                        className="img-fluid mb-md-5" 
                        width={500}/>
                    </div>
                    <div className='row mb-5 justify-content-center text-center'>
                        <p className='font-weight__700'>BigCommerce</p>
                        <p>Altas prestaciones con la flexibilidad de una plataforma SaaS</p>
                        <button class="learn-more" onClick={() => {navigate("/agencia-partner-bigcommerce/")}}>
                            <span class="circle secondary" aria-hidden="true">
                                <span class="icon arrow"></span>
                            </span>
                            <span class="button-text secondary">Más Información</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </section>
  );
}