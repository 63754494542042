import React from 'react';

import LayoutEs from '../components/LayoutEs';
import Scroll from '../components/Scroll';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Contact from '../components/Contact';
import SEO from '../components/Seo';
import Estrategia from '../components/Services/Estrategia';
import ScrollToTop from '../components/ScrollToTop';
import Calendly from '../components/Calendly';
import Desarrollo from '../components/Services/Desarrollo';
import Soporte from '../components/Services/Soporte';
import Partners from '../components/Services/Partners';
import Intro from '../components/Services/Intro';
import Howto from '../components/Services/Howto';

class Servicios extends React.Component {
  render() {
    return (
      <LayoutEs>
        <Header />
        <SEO
          title="Servicios soluciones ecommerce"
          description="Somos especialistas en soluciones Ecommerce y ofrecemos desarrollo ecommerce, diseño web y soporte para tu proyecto ecommerce."
          canonicalUrl="https://www.bekome.digital/servicios/"
        />
        <header className="masthead services d-flex">
          <div className="container text-center my-auto">
            <h1 className="mb-1 text-primary">Mejora tu negocio con soluciones de eCommerce personalizadas</h1>
            <Scroll type="id" element="estrategia" offset={0}>
              <a className="text-white vert-move" href="#estrategia" title="Servicios">
                <i className="icon-arrow-down"></i>
              </a>
            </Scroll>
          </div>
          <div className="overlay"></div>
        </header>

        <Estrategia />

        <Desarrollo />

        <Soporte />

        <Partners />

        <Intro />

        <Howto />

        <div className='container-fluid bg-grey'>
          <div className="container text-center text-white">
            <div className="row">
              <div className="col-12 text-secondary">
                <h4 className="font-size__l mt-5">
                  ¿Preparado para una experiencia Bekome?
                </h4>
                <p className='font-size__m'>
                  Gracias a nuestra experiencia, metodologías ágiles y la automatización de procesos, 
                  hemos creado un método de trabajo mejorado que nos permite desarrollar y testear más rápido para 
                  entregar soluciones de calidad en el menor tiempo posible.
                </p>
                <p className='font-size__m font-weight__700 text-uppercase mt-5 mb-5'>
                  Estás a un paso de conocer a tu nueva agencia
                </p>
              </div>
            </div>
          </div>
        </div>

        <Contact />

        <Footer />

        <ScrollToTop />

      </LayoutEs>
    );
  }
}

export default Servicios;
