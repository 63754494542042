import React from 'react';
import Scroll from '../Scroll';

export default function Intro() {
  return (
    <section className="content-section py-0" id="start">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-lg-6 soluciones-bg order-0"></div>
          <div className="col-12 col-lg-6 px-5 py-5 order-1">
            <h2 className="font-size__l mb-4 text-primary">
              Una solución para cada cliente
            </h2>
            <p className="font-size__m">
              Implementar tecnologías de este calibre puede traducirse en un <strong>gran ahorro</strong> en gastos operativos 
              y un aumento en las ventas, pero si no se trabajan correctamente pueden convertirse en una fuente de 
              errores y gastos de mantenimiento.
            </p>
            <p className="font-size__m">
              Por eso, en Beköme, solo trabajamos con herramientas que conocemos a la perfección.
            </p>
            <p className="font-size__m">
                En la actualidad, las soluciones que mejor rendimiento están ofreciendo a nuestros clientes son <strong>Magento 2 y BigCommerce</strong>, 
                puesto que se trata de plataformas con altas prestaciones y gran versatilidad, sin embargo no son las únicas que podemos ofrecerte. 
            </p>
            <p className="font-size__m">
              Nuestro objetivo principal es satisfacer las necesidades de nuestros clientes y para ello buscamos la 
              solución que mejor se adapte a cada caso. 
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
