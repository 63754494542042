import React from 'react';
import Scroll from '../Scroll';

export default function Intro() {
  return (
    <section className="mb-5" id="howto">
      <div className="container text-center">
        <div className="row mt-lg-5">
          <div className="col-12 mb-5">
          <h4 className="font-size__l text-secondary">Cómo trabajamos</h4>
          </div>
          <div className="col-12 col-sm-6 col-md-4 mb-lg-5  mb-4">
            <h4 className="text-uppercase">1. Welcome to Bekome</h4>
            <p className="font-size__m">
              El primer paso es conocernos y hablar sobre tu proyecto en una sesión de consultoría de 30 min.
            </p>
          </div>
          <div className="col-12 col-sm-6 col-md-4 mb-lg-5 mb-4">
          <h4 className="text-uppercase">2. Entrega de propuesta</h4>
            <p className="font-size__m">
              Estudiamos la situación y te presentamos la propuesta que mejor se adapte a  tus necesidades.
            </p>
          </div>
          <div className="col-12 col-sm-6 col-md-4 mb-lg-5 mb-4">
            <h4 className="text-uppercase">3. Definición y diseño</h4>
            <p className="font-size__m">
              Diseñamos la estrategia para mejorar el crecimiento de tu negocio y trazamos el plan para conseguirlo.
            </p>
          </div>
          <div className="col-12 col-sm-6 col-md-4 mb-lg-5 mb-4">
            <h4 className="text-uppercase">4. Desarrollo</h4>
            <p className="font-size__m">
              Durante la implementación del proyecto, te iremos informando de nuestos avances periódicamente, 
              para que puedas seguir la evolución del proyecto.
            </p>
          </div>
          <div className="col-12 col-sm-6 col-md-4 mb-lg-5 mb-4">
            <h4 className="text-uppercase">5. Despliegue</h4>
            <p className="font-size__m">
              Nos encargamos de poner en producción tu eCommerce con las tecnologías más punteras. Docker, AWS, Kubernetes, etc.
            </p>
          </div>
          <div className="col-12 col-sm-6 col-md-4 mb-lg-5">
            <h4 className="text-uppercase">6. Soporte</h4>
            <p className="font-size__m">
              Una vez publicado el proyecto, monitorizamos y te damos soporte para cambios y nuevas necesidades.
            </p>
          </div>
        </div>
      </div>
      <div className="container text-center mt-lg-5 mt-5 mt-sm-0">
          <div className="row">
            <div className="col-lg-10 mx-auto">
              <Scroll type="id" element="calendly" offset={0}>
                <a className="btn btn-secondary btn-lg" href="#calendly">
                  ¿Hablamos?
                </a>
              </Scroll>
            </div>
          </div>
      </div>
    </section>
  );
}
