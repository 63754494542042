import React from 'react';
import Scroll from '../Scroll';

export default function Desarrollo() {
  return (
    <section className="expertise-section" id="desarrollo">
      <div className="container-fluid">
        <div className="row">
            <div className="col-12 col-lg-6 desarrollo-bg order-1 order-lg-0"></div>
            <div className="col-12 col-lg-6 text-left my-5 order-0 order-lg-1 pl-5">
              <h3 className="font-size__l text-primary mb-5">
                Desarrollo Ecommerce
              </h3>
              <h4 className='h4 text-uppercase'><u>Desarrollo de proyecto</u></h4>
              <div className="row mb-4">
                <div className="col-10">
                  <p className="font-size__m">
                    En Beköme Digital estamos especializados en <strong>proyectos a medida</strong> y, 
                    en caso de que la plataforma no las proporcione, creamos las funcionalidades que necesites para 
                    incrementar la eficiencia de tu negocio.
                  </p>
                </div>
              </div>
              <h4 className='h4 text-uppercase'><u>Integración de sistemas</u></h4>
              <div className="row mb-4">
                <div className="col-10">
                  <p className="font-size__m">
                    Seguro que para llevar a cabo las tareas comerciales de tu empresa utilizas diferentes 
                    herramientas: ERPs, CRMs, PIMs, etc. Tenerlas agrupadas e integradas en tu eCommerce te ahorrará tiempo 
                    en los procesos y <strong>te proporcionará una gestión más eficaz</strong>.
                  </p>
                </div>
              </div>
              <h4 className='h4 text-uppercase'><u>Diseño y UX</u></h4>
              <div className="row mb-4">
                <div className="col-10">
                  <p className="font-size__m">
                    <strong>Somos expertos en crear experiencias eCommerce de valor añadido</strong>. 
                    Nuestro objetivo es simplificar, mejorar y enriquecer la experiencia de compra, mejorando no solo la 
                    experiencia de usuario, sino también la experiencia de cliente.
                  </p>
                </div>
              </div>
              <h4 className='h4 text-uppercase'><u>Replatforming</u></h4>
              <div className="row mb-4">
                <div className="col-10">
                  <p className="font-size__m">
                    Si cada vez que quieres añadir una nueva función a tu plataforma eCommerce te encuentras 
                    con obstáculos que te lo impiden, quizá haya llegado <strong>el momento de cambiar</strong>.<br />
                    Nosotros te ayudaremos a encontrar la solución más adecuada para tu negocio.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
    </section>
  );
}
